import React, { useEffect, useState } from 'react';
import { StyleHeader, StylePage } from '@utils/style.utils';
import { useTranslation } from 'react-i18next';
import { SeasonsOrderByYears } from '@src/models/api/seasons';
import { getSeasons } from '@api/business';
import { BriefAsset } from '@utils/constantes.utils';
import { LanguageKey } from '@languages/enum';
import { IconName } from '@utils/data/enums/icons.enum';
import { Format } from '@utils/enum.utils';
import { Button, SeasonsList } from '@src/components';
import PopupManager from '@components/popups/PopupManager';
import AddSeasonDialog from '@components/popups/AddSeasonDialog';

function HomePage() {
  const { t } = useTranslation();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [seasons, setSeasons] = useState<SeasonsOrderByYears[]>([]);
  const [error, setError] = useState<string>('');

  const handlePopup = (isOpen: boolean) => {
    setPopupOpen(isOpen);
  };

  const fetchSeasons = async() => {
    try {
      const seasonsByYears = await getSeasons(false);
      setSeasons(seasonsByYears);
    } catch (error) {
      setError(`Error fetching season:, ${error}`);
    }
  };

  useEffect(() => {
    fetchSeasons();
  }, []);

  return (
    <StylePage>
      <StyleHeader>
        <h1>{BriefAsset}</h1>
        <Button action={() => { handlePopup(true); }} text={t(LanguageKey.AddNewSeason)} icon={IconName.ADD} format={Format.Thin} />
      </StyleHeader>
      {
        seasons
          ? (
            <div>
              <SeasonsList seasons={seasons}/>
              <PopupManager isOpen={isPopupOpen} onClose={() => { handlePopup(false); }}>
                <AddSeasonDialog isOpen={isPopupOpen} closePopup={() => { handlePopup(false); }} updateSeasonsList={fetchSeasons} />
              </PopupManager>
            </div>
            )
          : <div>{ error }</div>
      }
    </StylePage>
  );
}

export default HomePage;
