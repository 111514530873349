import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { StyledPopupHeader, StyledSubmitButton } from '@components/popups/PopupManager/PopupManager.styled';
import {
  AddSeasonDialogContentStyled,
  AddSeasonDialogStyled,
  ExtendedStyledPopupContent
} from '@components/popups/AddSeasonDialog/AddSeasonDialog.styled';
import { SeasonsGlobalInformationsRequest } from '@models/api/seasons';
import { IconName } from '@utils/data/enums/icons.enum';
import { LanguageKey } from '@languages/enum';
import { SeasonFormValues } from '@models/forms/season.form';
import { getFiveNextYear, getTwoFirstChars } from '@utils/fonctions.utils';
import { Axis, Seasons } from '@utils/data/enums/generals.enum';
import { createSeason } from '@api/business';
import { Button } from '@src/components';
import { ComponentType, Format } from '@utils/enum.utils';
import { BasicButton, StyledText, StyleVerticallySeparator } from '@utils/style.utils';
import { NewSeason } from '@utils/constantes.utils';
import FormTextInput from '@components/inputs/FormTextInput';
import FormSelectInput from '@components/inputs/FormSelectInput';
import FormDateInput from '@components/inputs/FormDateInput';

interface AddSeasonDialogProps {
  closePopup: () => void;
  updateSeasonsList: () => void;
  isOpen: boolean
}

function AddSeasonDialog({ closePopup, updateSeasonsList, isOpen }: AddSeasonDialogProps): JSX.Element {
  const { t } = useTranslation();
  const [years, setYears] = useState<string[]>([]);
  const [isUpdatedName, setIsUpdatedName] = useState(false);
  const [showError, setShowError] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  const { control, handleSubmit, watch, setValue } = useForm<SeasonFormValues>({
    defaultValues: {
      year: '',
      season: '',
      axis: '',
      name: '',
      deadlineDef: '',
      deadlineNoDef: '',
      annexe: ''
    }
  });

  const year = watch('year');
  const season = watch('season');
  const axis = watch('axis');

  const seasonName = `${getTwoFirstChars(season, 'start')}${getTwoFirstChars(year, 'end')} ${axis}`;

  useEffect(() => {
    setYears(getFiveNextYear());
  }, []);

  useEffect(() => {
    if (!isUpdatedName) {
      setValue('name', seasonName);
    }
  }, [year, season, axis, isUpdatedName, setValue]);

  const giveAName = (value: string) => {
    setValue('name', value);
    setIsUpdatedName(true);
  };

  const handleAddSeason = async(data: SeasonFormValues) => {
    const seasonData: SeasonsGlobalInformationsRequest = {
      year: data.year,
      season: data.season,
      name: data.name === '' || data.name.trim().length === 0 ? seasonName : data.name,
      axis: data.axis,
      deadlineDef: data.deadlineDef,
      deadlineNoDef: data.deadlineNoDef,
      annexe: data.annexe
    };

    console.log(seasonData);

    try {
      const newSeason = await createSeason(seasonData);
      if (!newSeason) {
        throw new Error('Erreur lors de la création de la saison.');
      }
      updateSeasonsList();
      closePopup();
    } catch (error) {
      console.error('Error creating season:', error);
      setShowError(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      closePopup();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <ExtendedStyledPopupContent ref={popupRef}>
      <AddSeasonDialogStyled>
        <StyledPopupHeader>
          <Button action={closePopup} icon={IconName.CROSS} format={Format.Classic} type={ComponentType.Classic} />
        </StyledPopupHeader>
        <h2>{t(NewSeason)}</h2>
        {showError && <StyledText type={ComponentType.Canceled}>{t(LanguageKey.CreateSeasonError)}</StyledText>}
        <form onSubmit={handleSubmit(handleAddSeason)}>
          <AddSeasonDialogContentStyled>
            <div>
              <FormSelectInput
                title={LanguageKey.Year}
                control={control}
                name="year"
                isMandatory
                options={years.map(year => ({ value: year, label: year }))}
              />

              <FormSelectInput
                title={LanguageKey.Season}
                control={control}
                isMandatory
                name="season"
                options={[
                  { value: Seasons.SUMMER, label: t(Seasons.SUMMER) },
                  { value: Seasons.SPRING, label: t(Seasons.SPRING) },
                  { value: Seasons.FALL, label: t(Seasons.FALL) },
                  { value: Seasons.HOLIDAY, label: t(Seasons.HOLIDAY) }
                ]}
              />

              <FormSelectInput
                title={LanguageKey.Axis}
                control={control}
                isMandatory
                name="axis"
                options={[
                  { value: Axis.MAKEUP, label: t(Axis.MAKEUP) },
                  { value: Axis.SKINCARE, label: t(Axis.SKINCARE) },
                  { value: Axis.ACCESSORIES, label: t(Axis.ACCESSORIES) },
                  { value: Axis.OTHERS, label: t(Axis.OTHERS) },
                  { value: Axis.MEDIA, label: t(Axis.MEDIA) },
                  { value: Axis.HOLIDAY, label: t(Axis.HOLIDAY) }
                ]}
              />

              <FormTextInput
                title={LanguageKey.Name}
                control={control}
                name="name"
                isMandatory
                onChange={giveAName}
              />
            </div>

            <StyleVerticallySeparator />

            <div>
              <FormTextInput
                title={LanguageKey.Annexe}
                control={control}
                name="annexe"
              />

              <FormDateInput title={LanguageKey.DeadlineDef} isMandatory control={control} name="deadlineDef" />
              <FormDateInput title={LanguageKey.DeadlineNoDef} isMandatory control={control} name="deadlineNoDef" />
            </div>
          </AddSeasonDialogContentStyled>
          <StyledSubmitButton>
            <BasicButton type='submit'>
              <Button action={() => {}} format={Format.Thin} text={t(LanguageKey.AddSeason)} />
            </BasicButton>
          </StyledSubmitButton>
        </form>
      </AddSeasonDialogStyled>
    </ExtendedStyledPopupContent>
  );
}

export default AddSeasonDialog;
