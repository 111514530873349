import styled from 'styled-components';

export const StyledProductsColumnList = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    > * {
        display: flex;
        margin: 1%;
    }
`;

export const StyleCategorySection = styled.div`
    margin: 0 3rem;
`;
