import React from 'react';
import * as Icons from '@mui/icons-material';
import { StyledIcon } from '@components/Icon/Icon.styled';
import { ComponentType } from '@utils/enum.utils';

interface IIconButtonProps {
  icon: keyof typeof Icons;
  type?: ComponentType;
  border?: boolean;
  position?: string;
  iconWidth?: string;
  iconHeight?: string;
  iconColor?: string
}

function Icon({ icon, border, position, type, iconWidth, iconHeight, iconColor }: IIconButtonProps) {
  let svgProps = {};

  if (iconWidth && iconHeight) {
    svgProps = {
      style: { width: iconWidth, height: iconHeight, color: iconColor }
    };
  }

  return (
    <StyledIcon type={type} border={border} position={position}>
      {React.createElement(Icons[icon], svgProps)}
    </StyledIcon>

  );
}

export default Icon;
