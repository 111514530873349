import React from 'react';
import { ComponentType } from '@utils/enum.utils';
import { StyledElementPhoto, StyledProductsInfo } from '@components/ProductElementList/ProductsElementList.styled';
import { IGlobalInformationsProductBySeasonId } from '@models/api/products';
import { StyleDisplayColumnFlexStart, StyleDisplayRow, StyledText } from '@utils/style.utils';
import { ProductText } from '@utils/constantes.utils';
import { convertirTimeZoneEnFormatDate } from '@utils/fonctions.utils';
import { Button } from '@src/components';
import ImageComponent from '@components/Image';
import { useNavigate } from 'react-router-dom';

interface IProductsElementListProps {
  product: IGlobalInformationsProductBySeasonId
}

function ProductsElementList({ product }: IProductsElementListProps) {
  const navigation = useNavigate();
  const navigate = () => {
    navigation(`/product/${product.id}`);
  };

  return (
    <StyleDisplayRow border={true}>
      <StyledElementPhoto>
        {product.image && <ImageComponent url={product.image[0]} isImgCarousel/>}
      </StyledElementPhoto>
      <StyleDisplayColumnFlexStart>
        <StyledText type={ComponentType.InfoListTitle}>{ product.name }</StyledText>
        <StyledProductsInfo>
          <StyledText type={ComponentType.InfoListText}>{ product.sku + ProductText.SKUs }</StyledText>
          <StyledText type={ComponentType.InfoListText}>{ product.image.length + ProductText.Visuals } </StyledText>
          <StyledText type={ComponentType.InfoListText}>{ ProductText.LastUpdate + convertirTimeZoneEnFormatDate(product?.lastUpdate ?? null) }</StyledText>
        </StyledProductsInfo>
        <StyleDisplayRow>
          <Button action={() => { navigate(); }} type={ComponentType.InfoButtonText} text="VIEW DETAILS" />
          <Button action={() => {}} type={ComponentType.InfoButtonText} text="..." />
        </StyleDisplayRow>
      </StyleDisplayColumnFlexStart>
    </StyleDisplayRow>
  );
}

export default ProductsElementList;
