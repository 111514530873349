import React, { useEffect, useState } from 'react';
import * as Icons from '@mui/icons-material';
import { GeneralStatus } from '@utils/data/enums/generals.enum';
import { IconName } from '@utils/data/enums/icons.enum';
import {
  StyleDivButtonSeason,
  StyleDownloadBtn,
  StyleLockOrEdit
} from '@components/OverloadSeason/OverloadSeason.styled';
import { ComponentType, Format } from '@utils/enum.utils';
import { SeasonsGlobalInformations } from '@models/api/seasons';
import { StyleDisplayColumnSpaceBetween } from '@utils/style.utils';
import { Button, Icon } from '@src/components';

interface IIconButtonProps {
  action: () => void;
  element: SeasonsGlobalInformations
}

function OverloadSeason({ action, element }: IIconButtonProps) {
  const [icon, setIcon] = useState<keyof typeof Icons | null>(null);
  const [type, setType] = useState(ComponentType.Classic);

  useEffect(() => {
    switch (element.status) {
      case GeneralStatus.BLOCKED:
        setType(ComponentType.Blocked);
        setIcon(IconName.LOCK);
        break;
      default:
        setType(ComponentType.Open);
        setIcon(IconName.EDIT);
        break;
    }
  }, []);

  return (
    <div>
      <StyleDivButtonSeason>
        <Button key={element.id} action={action} text={element.name} format={Format.Large} />
        <StyleDisplayColumnSpaceBetween>
          <StyleLockOrEdit>
            {icon ? <Icon icon={icon} type={type} border={true} iconWidth='0.4em' iconHeight='0.4em' iconColor='white'/> : <></>}
          </StyleLockOrEdit>
          <StyleDownloadBtn>
            <Icon icon={IconName.DOWNLOAD} type={type} iconWidth='0.5em' iconHeight='0.5em' iconColor='white'/>
          </StyleDownloadBtn>
        </StyleDisplayColumnSpaceBetween>
      </StyleDivButtonSeason>
    </div>
  );
}

export default OverloadSeason;
