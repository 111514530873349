import styled from 'styled-components';

export const StyledTextBoxInput = styled.input`
    padding: 10px 1%;
    width: 98%;
    size: 20px;
    border: 1px solid var(--border-input-color);
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
    &:focus {
        border-color: var(--menu-accentuation-color);
        outline: none;
    }
`;

export const StyledSelectInput = styled.select`
    padding: 10px 1%;
    width: 100%;
    height: var(--height);
    border: 1px solid #d1d1d6;
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
    &:focus {
        border-color: var(--menu-accentuation-color);
        outline: none;
    }
`;

export const StyleUploadZoneInput = styled.input`
    padding: 10px 1%;
    width: 98%;
    size: 20px;
    border: 2px solid var(--border-input-color);
    border-radius: var(--border-radius);
    transition: all 0.3s ease;
            &:focus {
                border-color: var(--menu-accentuation-color);
                outline: none; 
        }
`;
