import React, { ReactNode, ReactElement, cloneElement } from 'react';
import { StyledPopup } from '@components/popups/PopupManager/PopupManager.styled';

interface PopupManagerProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void
}

function PopupManager({ children, isOpen, onClose }: PopupManagerProps): JSX.Element {
  return (
    <StyledPopup isopen={isOpen}>
        {isOpen && cloneElement(children as ReactElement<any>, { closePopup: onClose })}
    </StyledPopup>
  );
}

export default PopupManager;
