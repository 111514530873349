import moment from 'moment';

export function getTwoFirstChars(str: string, position: 'start' | 'end'): string {
  if (position === 'start') {
    return str.slice(0, 2);
  } else if (position === 'end') {
    return str.slice(-2);
  } else {
    throw new Error("Invalid position. Use 'start' or 'end'.");
  }
}

export function getFiveNextYear(): string[] {
  const currentYear = new Date().getFullYear();
  const yearsList: string[] = [];

  for (let i = 0; i < 5; i++) {
    yearsList.push((currentYear + i).toString());
  }
  return yearsList;
}

export function convertirTimeZoneEnFormatDate(heureAvecTimeZone: Date | null): string {
  if (heureAvecTimeZone) {
    const date = moment(heureAvecTimeZone);

    return date.format('DD/MM/YYYY');
  }

  return '';
}

export function toPascalCaseWithSpaces(text: string) {
  const words = text.split(' ');

  const pascalCaseWords = words.map(word => {
    if (word.trim() === '') return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return pascalCaseWords.join(' ');
}

export function updateNestedField<T extends object, K extends keyof T>(
  obj: T,
  field: K,
  nestedField: keyof T[K],
  value: any
): T {
  return {
    ...obj,
    [field]: {
      ...obj[field],
      [nestedField]: value
    }
  };
}
