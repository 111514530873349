export const BriefAsset = 'BRIEF ASSET';
export const NewSeason = 'New Season';
export const Home = 'Home';
export const SeasonalBrief = 'Seasonal Brief';

export enum CarrouselText {
  ImageInput = 'ImageInput',
  AlertUpload = 'Please upload an image file.',
  Next = 'NEXT',
  Back = 'BACK',
  Plus = '+',
}

export enum ProductText {
  Visuals = ' Visuals ',
  SKUs = ' SKUs ',
  Sku = 'SKU',
  AlertUpload = 'Please upload an image file.',
  Upload = 'UPLOAD',
  ImageInput = 'ImageInput',
  AdditionalImage = 'Additional photos',
  AmbitionsSellOut = 'Ambitions sell out',
  KeyElementsToHighlightInVisuals = 'Key elements to highlight in visuals',
  MopUser = 'MOP user',
  DigitUser = 'Digit user',
  MarketingProductUser = 'Marketing product user',
  LastUpdate = ' Last update : ',
  Product = ' product ',
  Products = ' products ',
  Name = 'Name',
  Category = 'Category',
  SubCategory = 'Subcategory',
  LevelOfActivation = 'Level of activation',
  AssortimentFile = 'Assortiment file',
  FileInput = 'FileAssortmentInput',
  UploadFile = 'Choose a file',
  AddExcel = 'Add an excel',
  Image = 'image/*',
  DragImage = 'or drag images',
  DragPackshot = 'or drag Packshots',
  BigBet = 'BIG BET',
  Strategique = 'STRATEGIQUE',
  Standard = 'STANDARD',
  Pillar = 'PILLAR',
  Status = 'Status',
  SpecificPackaging = 'Specific Packaging',
  OneShot = 'One Shot',
  OneYearCatalogue = 'One year Catalogue',
  Catalogue = 'Catalogue'
}
