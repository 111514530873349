import styled from 'styled-components';

export const StyleGlobalNavigationBar = styled.div`
    display: flex!important;
    width: 12rem;
    overflow-y: auto;
`;

export const StyledNavigationBar = styled.div`
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    background-color: var(--menu-background-color);
`;

export const StyledTitleNavigationBar = styled.div`
    background-color: var(--menu-accentuation-color);
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContentListNavigationBar = styled.div`
    width: 100%;
    margin-top: 1rem;
    overflow: hidden;
    height: 100%;
`;

export const StyledIconNavigationBar = styled.div`
    color: #fff;
    transition: transform 0.3s ease;
    cursor: pointer;
    margin-right: 0.4rem;
`;

export const StyledContentNavigationBar = styled.div<{ isLogOut?: boolean; show?: boolean; depth?: number }>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    position: relative;
    bottom: auto;
    left: auto;
    width: auto;
    margin-bottom: auto;
    color: #fff;
    padding: 0.5rem 1rem;
    align-items: center;
    margin-left: ${({ depth }) => (depth && depth > 0 ? `${depth}rem` : '0')};

    &:hover {
        background-color: #444343;
        cursor: pointer;
    }

    ${({ isLogOut }) =>
            isLogOut &&
            `
        position: fixed;
        bottom: 0;
        left: 0;
        width: 11rem;
        margin-bottom: 1rem;
    `}
`;
