export enum Categories {
  EYES = 'EYES',
  LIPS = 'LIPS',
  FACE = 'FACE',
  NAILS = 'NAILS',
  ACCESS = 'ACCESS'
}

export enum Levels {
  BIGBET = 'BIG BET',
  STRATEGIC = 'STRATEGIC',
  CLASSIC = 'STANDARD',
  PILAR = 'PILAR'
}

export enum Axis {
  MAKEUP = 'MAKE-UP',
  SKINCARE = 'SKINCARE',
  ACCESSORIES = 'ACCESSORIES',
  HOLIDAY = 'HOLIDAY',
  MEDIA = 'MEDIA ALWAYS-ON',
  OTHERS = 'OTHERS'
}

export enum GeneralStatus {
  BLOCKED = 'BLOCKED',
  OPEN = 'OPEN'
}

export enum Seasons {
  SPRING = 'SPRING',
  SUMMER = 'SUMMER',
  FALL = 'FALL',
  HOLIDAY = 'HOLIDAY'
}
