import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IProduct } from '@models/api/products';
import { StylePage } from '@utils/style.utils';
import { getProductById } from '@api/business/productBusiness';
import { ProductPageHeader } from '@components/Headers';

function ProductPage() {
  const { productId } = useParams();
  const [product, setProduct] = useState<IProduct>();
  const [error, setError] = useState<string>('');

  const fetchProduct = async() => {
    try {
      if (productId) {
        const productInformations = await getProductById(productId);
        if (productInformations) {
          setProduct(productInformations);
        }
      } else {
        throw Error();
      }
    } catch (error) {
      setError(`Error fetching product: ${error}`);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  return (
    <StylePage>
      <div>
        {error && <h1>{error}</h1>}
        {product && <ProductPageHeader product={product} />}
      </div>
    </StylePage>
  );
}

export default ProductPage;
