import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StylePage } from '@utils/style.utils';
import { ISeason } from '@models/api/seasons';
import { getSeasonById } from '@api/business/seasonBusiness';
import { SeasonPageHeader } from '@components/Headers';
import ProductsList from '@components/ProductsList/ProductsList';
import { getProductBySeasonId } from '@api/business/productBusiness';
import { IProductsByLevel } from '@models/api/products';

function SeasonPage() {
  const { seasonId } = useParams();
  const [season, setSeason] = useState<ISeason | null>(null);
  const [products, setProducts] = useState<IProductsByLevel[] | null>(null);
  const [error, setError] = useState<string>('');

  const fetchSeason = async() => {
    try {
      if (seasonId) {
        const season = await getSeasonById(seasonId);
        if (season) {
          setSeason(season);
        }
      } else {
        throw Error();
      }
    } catch (error) {
      setError(`Error fetching season:, ${error}`);
    }
  };

  const fetchProducts = async() => {
    try {
      if (seasonId) {
        const productsList = await getProductBySeasonId(seasonId);
        if (productsList) {
          console.log(productsList);
          setProducts(productsList);
        }
      } else {
        throw Error();
      }
    } catch (error) {
      setError(`Error fetching product: ${error}`);
    }
  };

  useEffect(() => {
    fetchSeason();
    fetchProducts();
  }, [seasonId]);

  return (
    <StylePage>
      { season
        ? (
          <div>
            <SeasonPageHeader season={season} productNumber={2} assetNumber={15} />
            {
              products
                ? (
                  <ProductsList products={products} />
                  )
                : <></>
            }
          </div>
          )
        : <></>}
      {error ? <div>{error}</div> : <></>}
    </StylePage>
  );
}

export default SeasonPage;
