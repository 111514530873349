import styled, { css } from 'styled-components';
import { ComponentType, Format } from '@utils/enum.utils';

export const StylePage = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;

    overflow-y: scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
`;

interface IStyleWidthContainer {
  width?: string
}

export const StyleWidthContainer = styled.div<IStyleWidthContainer>`
    width: ${props => props.width ?? '100%'};
`;

export const StyleApp = styled.div`
    flex: 1;
    overflow-y: auto;
`;

interface IStyleText {
  fontSize?: string;
  fontWeight?: string;
  type?: ComponentType;
  format?: Format
}

export const StyledText = styled.h1<IStyleText>`
    font-size: ${props => props.fontSize ?? '16px'};
    font-weight: ${props => props.fontWeight ?? 'normal'};
    
    ${({ type }) => {
  switch (type) {
    case ComponentType.Button:
      return css`
            color: var(--text-color-button);
            width: max-content;
                `;
      case ComponentType.InfoListText:
          return css`
              font-size: 14px;
              margin: 0;
          `;
      case ComponentType.InfoButtonText:
          return css`
              color: var(--text-color-button);
              font-size: 14px;
          `;
      case ComponentType.InfoListTitle:
          return css`
              font-size: 16px;
              margin: 0.4rem 0;
              font-weight: bolder;
          `;
    case ComponentType.Comment:
      return css`
                    color: var(--light-grey-color);
                    font-weight: lighter;
                `;
    case ComponentType.Waiting:
      return css`
                    color: var(--waiting-color)
                `;
    case ComponentType.Canceled:
      return css`
                    color: var(--error-todo-color)
                `;
    case ComponentType.Error:
      return css`
                    color: var(--error-todo-color)
                `;
    case ComponentType.Validated:
      return css`
          color: var(--validated-color)
        `;
    case ComponentType.Classic:
      return css`
          color: var(--text-color)
        `;
      case ComponentType.FormInput:
          return css`
              font-size: 12px;
              font-weight: 600;
              margin-bottom: 0;
          `;
      case ComponentType.SubTitle:
          return css`
              color: var(--text-color);
              font-size: 24px;
              font-weight: bold;
          `;
      case ComponentType.SubSubTitle:
          return css`
              color: var(--text-color);
              font-size: 24px;
              font-weight: normal;
          `;
      case ComponentType.Title:
          return css`
              color: var(--text-color);
              font-size: 28px;
              font-weight: bold;
          `;
      case ComponentType.BriefAsset:
          return css`
              color: var(--text-color-button);
              font-size: 28px;
              font-weight: bold;
          `;
    default:
      return css`
                    color: var(--text-color);
                `;
  }
}}    
`;

export const StyleHeader = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--box-background-color);
    padding: 1rem 1.5rem;
`;

interface IStyleSeparator {
  color?: string
}

export const StyleVerticallySeparator = styled.hr<IStyleSeparator>`
    background-color: ${props => props.color ?? 'var(--menu-accentuation-color)'};
    size: 1px;
    height: 100%;
`;

export const StyleHorizontallySeparator = styled.hr<IStyleSeparator>`
    color: ${props => props.color ?? 'var(--text-color)'};
    size: 1px;
    width: 100%;
`;

export const StyledBox = styled.div`
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    background-color: var(--box-background-color);
    border-radius: var(--border-radius);
    margin: 1.5rem 1.5rem 0 1.5rem;
    padding: 1rem 1rem 0 ;
`;

interface IStyleDisplayRow {
  marginright?: string;
  margin?: string;
  justifycontent?: string;
  alignitems?: string;
  border?: boolean;
  padding?: string;
  width?: string;
  gap?: string
}

export const StyleDisplayRow = styled.div<IStyleDisplayRow>`
    display: flex;
    flex-direction: row;
    flex-wrap: unset;
    justify-content: ${props => props.justifycontent ?? ''};
    align-items: ${props => props.alignitems ?? 'center'};
    padding: ${props => props.padding ?? ''};
    width: ${props => props.width ?? ''};
    gap: ${props => props.gap ?? '0.2rem'}; 

    ${({ border }) => border && css`
        border: var(--text-color) 1px solid;
        border-radius: var(--border-radius);
    `}
`;

interface IStyleDisplayColumn {
  margin?: string;
  border?: boolean;
  padding?: string
}

export const StyleDisplayColumn = styled.div<IStyleDisplayColumn>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0.4rem;
    padding: ${props => props.padding ?? ''};
    width: 100%;
    height: 100%;

    ${({ border }) => border && css`
        border: var(--text-color) 3px solid;
        border-radius: 50%;
    `}
`;

export const StyleDisplayColumnSpaceBetween = styled(StyleDisplayColumn)`
    justify-content: space-between;
    height: 100%;
    margin: 0.4rem 0;
`;

export const StyleDisplayColumnFlexStart = styled(StyleDisplayColumn)`
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    margin: 0.4rem 0;
`;

export const BasicButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
`;
