import React, { useState } from 'react';
import { ISeason } from '@models/api/seasons';
import { ComponentType, Format } from '@utils/enum.utils';
import { convertirTimeZoneEnFormatDate } from '@utils/fonctions.utils';
import {
  StyleDisplayColumn,
  StyleDisplayColumnFlexStart,
  StyleDisplayRow,
  StyledText,
  StyleHeader
} from '@src/utils/style.utils';
import { Icon, Button } from '@src/components';
import PopupManager from '@components/popups/PopupManager';

interface ISeasonPageHeaderInformations {
  season: ISeason;
  productNumber: number;
  assetNumber: number
}

function SeasonPageHeader({ season, assetNumber, productNumber }: ISeasonPageHeaderInformations) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [editableValues, setEditableValues] = useState(season);

  // const handleFieldChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   field: keyof ISeason,
  //   nestedField?: string
  // ) => {
  //   if (nestedField) {
  //     setEditableValues((prevValues) =>
  //       updateNestedField(prevValues, field, nestedField as never, e.target.value)
  //     );
  //   } else {
  //     setEditableValues({
  //       ...editableValues,
  //       [field]: e.target.value
  //     });
  //   }
  // };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
      <StyleHeader>
        <StyleDisplayRow alignitems='flex-start'>
          <StyleDisplayColumnFlexStart>
              <StyledText type={ComponentType.Title}>{`${season.name}`}</StyledText>
              <StyleDisplayRow>
                <Icon icon="Edit"/>
                <StyledText> {`Last update: ${convertirTimeZoneEnFormatDate(season.updatedDate)}`} </StyledText>
              </StyleDisplayRow>
            <StyleDisplayRow>
              <Icon icon="Pages"/>
              <StyledText> {`Merch Annexes: ${season.annexe}`} </StyledText>
            </StyleDisplayRow>
            <Button action={handleOpenPopup} text="Add a product" icon="Add" format={Format.Thin}/>
            <PopupManager isOpen={isPopupOpen} onClose={handleClosePopup} >
            </PopupManager>
          </StyleDisplayColumnFlexStart>

          <StyleDisplayRow border={true} width={'50%'} >
            <StyleDisplayColumn>
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="Timer"/>
                <StyledText> {`DEF Brief Asset Deadline: ${convertirTimeZoneEnFormatDate(season.deadlineDef)}`} </StyledText>
              </StyleDisplayRow>
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="Timer"/>
                <StyledText> {`NON DEF Brief Asset Deadline: ${convertirTimeZoneEnFormatDate(season.deadlineDef)}`} </StyledText>
              </StyleDisplayRow>
            </StyleDisplayColumn>

            <StyleDisplayColumn>
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="Icecream"/>
                <StyledText> {`${productNumber} products`} </StyledText>
              </StyleDisplayRow>
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="WebAsset"/>
                <StyledText> {`${assetNumber} assets`} </StyledText>
              </StyleDisplayRow>
            </StyleDisplayColumn>
          </StyleDisplayRow>
        </StyleDisplayRow>
      </StyleHeader>
  );
}

export default SeasonPageHeader;
