import React, { useState, useEffect } from 'react';
import { StyledImage, StyledImageContainer, StyledImagePlaceholder } from '@components/Image/Image.styled';

interface ImageComponentProps {
  url: string;
  isImgCarousel?: boolean
}

const ImageComponent: React.FC<ImageComponentProps> = ({ url, isImgCarousel }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageUrl(url);
    };
    img.onerror = () => {
      setImageUrl('/placeholder.jpg');
    };
    img.src = url;
  }, [url]);

  return (
    <StyledImageContainer isImgCarousel={isImgCarousel}>
      {imageUrl && imageUrl !== ''
        ? (
        <StyledImage src={imageUrl} alt="Image" className="image"/>
          )
        : (
        <StyledImagePlaceholder />
          )}
    </StyledImageContainer>
  );
};

export default ImageComponent;
