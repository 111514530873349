export enum Format {
  Large = 'large',
  Thin = 'thin',
  Icon = 'icon',
  Classic = 'classic',
  Info = 'info',
}

export enum ComponentType {
  Button = 'button',
  Title = 'Title',
  SubTitle = 'SubTitle',
  FormInput = 'FormInput',
  SubSubTitle = 'SubSubTitle',
  BriefAsset = 'BriefAsset',
  Classic = 'classic',
  Validated = 'validated',
  Waiting = 'waiting',
  Canceled = 'canceled',
  Comment = 'comment',
  Error = 'error',
  Text = 'text',
  File = 'file',
  Arrow = 'Arrow',
  Blocked = 'Blocked',
  Open = 'Open',
  InfoListText = 'InfoListText',
  InfoListTitle = 'InfoListTitle',
  InfoButtonText = 'InfoButtonText',
  ColumnSpaceBetween = 'ColumnSpaceBetween',
  ColumnEnd = 'ColumnEnd'
}
