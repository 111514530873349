import axiosInstance from '@utils/axios.utils';
import { ISeason, SeasonsGlobalInformationsRequest, SeasonsOrderByYears } from '@models/api/seasons';

export async function getSeasonsDA(isArchived: boolean): Promise<SeasonsOrderByYears[] | null> {
  try {
    const response = await axiosInstance.get(`seasons?is_archived=${isArchived}`);
    return response.data;
  } catch (error) {
    throw Error('Error from the server: get seasons');
  }
}

export async function createSeasonDataAccess(data: SeasonsGlobalInformationsRequest): Promise<ISeason | null> {
  try {
    const response = await axiosInstance.post('seasons/', data);
    return response.data;
  } catch (error) {
    throw Error('Error from the server: create season');
  }
}

export async function updateSeasonDataAccess(data: SeasonsGlobalInformationsRequest): Promise<ISeason | null> {
  try {
    const response = await axiosInstance.put('seasons/', data);
    return response.data;
  } catch (error) {
    throw Error('Error from the server: create season');
  }
}

export async function getSeasonByIdDA(id: string): Promise<ISeason | null> {
  try {
    const response = await axiosInstance.get(`seasons/${id}`);
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
}
