import { ISeason, SeasonsGlobalInformationsRequest, SeasonsOrderByYears } from '@models/api/seasons';
import {
  createSeasonDataAccess,
  getSeasonByIdDA,
  getSeasonsDA,
  updateSeasonDataAccess
} from '@api/dataAccess/seasonDataAccess';

export async function getSeasons(isArchived: boolean): Promise<SeasonsOrderByYears[]> {
  try {
    const seasons = await getSeasonsDA(isArchived);
    if (seasons && seasons.length > 0) {
      return seasons.sort((a: SeasonsOrderByYears, b: SeasonsOrderByYears) => Number(b.year) - Number(a.year));
    }
    return [];
  } catch (error) {
    throw Error('An error occurred');
  }
}

export async function createSeason(data: SeasonsGlobalInformationsRequest): Promise<ISeason | null> {
  try {
    return await createSeasonDataAccess(data);
  } catch (error) {
    throw Error('An error occurred');
  }
}

export async function updateSeason(data: SeasonsGlobalInformationsRequest): Promise<ISeason | null> {
  try {
    return await updateSeasonDataAccess(data);
  } catch (error) {
    throw Error('An error occurred');
  }
}

export async function getSeasonById(id: string): Promise<ISeason | null> {
  try {
    return await getSeasonByIdDA(id);
  } catch (error) {
    throw Error('An error occurred');
  }
}
