import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from '@src/App';
import i18n from '@languages/i18n';
import { StyleApp } from '@utils/style.utils';
import { NavigationBar, StyleGlobalNavigationBar } from '@src/components';
import '@src/index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <div style={{ maxHeight: window.innerHeight, maxWidth: window.innerWidth }}>
          <StyleGlobalNavigationBar>
            <NavigationBar/>
          </StyleGlobalNavigationBar>
          <StyleApp>
            <App/>
          </StyleApp>
        </div>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
