import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { StyledTextBoxInput } from '@components/inputs/Inputs.styled';
import { StyledText } from '@utils/style.utils';
import { useTranslation } from 'react-i18next';
import { ComponentType } from '@utils/enum.utils';

interface FormTextInputProps<T extends FieldValues> {
  control: Control<any>;
  name: Path<T>;
  title: string;
  isMandatory?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void
}

function FormTextInput<T extends FieldValues>({ title, control, name, placeholder, onChange, isMandatory }: FormTextInputProps<T>) {
  const { t } = useTranslation();

  return (
   <div>
     <StyledText type={ComponentType.FormInput}>{t(title)}{isMandatory ? ' *' : null}</StyledText>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <StyledTextBoxInput
          {...field}
          placeholder={placeholder}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e.target.value);
          }}
        />
      )}
    />
   </div>
  );
}

export default FormTextInput;
