import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SeasonsGlobalInformations, SeasonsOrderByYears } from '@models/api/seasons';
import { StyledElementsSeasonList } from '@components/SeasonsList/SeasonsList.styled';
import { StyledBox, StyledText, StyleHorizontallySeparator } from '@utils/style.utils';
import { OverloadSeason } from '@src/components';
import { ComponentType } from '@utils/enum.utils';

interface ISeasonListProps {
  seasons: SeasonsOrderByYears[]
}

function SeasonsList({ seasons }: ISeasonListProps) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const navigate = (season: SeasonsGlobalInformations) => {
    navigation(`/season/${season.id}`);
  };

  return (
    <div>
      {
        seasons.map(item => (
          <StyledBox key={item.year}>
            <StyledText type={ComponentType.SubTitle}>{item.year}</StyledText>
            <StyleHorizontallySeparator/>

            {item.seasons.map(seasonGroup => (
              <div key={seasonGroup.season}>
                <StyledText type={ComponentType.SubSubTitle}>{t(seasonGroup.season)} :</StyledText>
                        <StyledElementsSeasonList>
                          {seasonGroup.elements.map(element => (
                            <OverloadSeason key={element.id} action={() => {
                              navigate(element);
                            }} element={element} />
                          ))}
                </StyledElementsSeasonList>
              </div>
            ))}
          </StyledBox>
        ))}
    </div>
  );
}

export default SeasonsList;
