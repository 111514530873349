import styled from 'styled-components';

interface IStyledImageContainer {
  isImgCarousel?: boolean
}

interface IStyledImagebubble {
  isFlagSelector?: boolean;
  isHiglighted?: boolean
}

export const StyledImageContainer = styled.div<IStyledImageContainer>`
    position: ${props => props.isImgCarousel ? 'absolute' : ''};
    max-width: 100%;
    height: 100%;
    z-index: 1;
`;

export const StyledImageBubble = styled.div<IStyledImagebubble>`
    max-width: 2rem;
    height: 2rem;
    transition: all ease 0.3s;
    z-index: 1;
    padding: 0.1rem;
    border: ${props => props.isHiglighted ? '3px solid var(--text-color)' : '3px solid white '};
    border-radius: 50px;

    &:hover {
        opacity: ${props => props.isFlagSelector ? '0.5' : ''};
        cursor: pointer; 
    }
`;

export const StyledImagePlaceholder = styled.div`
    width: 100%;
    height: auto;
    background-color: var(--light-grey-color);
`;

export const StyledImage = styled.img`
    max-width: 100%;
    height: 100%;
`;
