import React, { useEffect, useState } from 'react';
import { StyledProductsColumnList, StyleCategorySection } from '@components/ProductsList/ProductsList.styled';
import ProductsElementList from '@components/ProductElementList';
import { IProductsByLevel } from '@models/api/products';
import { ComponentType } from '@utils/enum.utils';
import { IconName } from '@utils/data/enums/icons.enum';
import { Icon } from '@src/components';
import { StyledIconArrow } from '@components/Icon/Icon.styled';
import {
  StyledBox,
  StyleDisplayRow,
  StyledText,
  StyleHorizontallySeparator
} from '@utils/style.utils';
import { ProductText } from '@utils/constantes.utils';
import { toPascalCaseWithSpaces } from '@utils/fonctions.utils';

interface IProductsListProps {
  products: IProductsByLevel[]
}

interface ICategory {
  level: string;
  category: string
}

function ProductsList({ products }: IProductsListProps) {
  const [isOpenLevelOfActivation, setOpenLevelOfActivation] = useState<string[]>([]);
  const [isOpenCategory, setOpenCategory] = useState<ICategory[]>([]);
  const [error, setError] = useState('');

  const handleLevelOfActivationClick = (level: string) => {
    if (isOpenLevelOfActivation.includes(level)) {
      setOpenLevelOfActivation(isOpenLevelOfActivation.filter(isOpenLevelOfActivation => isOpenLevelOfActivation !== level));
    } else {
      setOpenLevelOfActivation([...isOpenLevelOfActivation, level]);
    }
  };

  const handleCategoryClick = (level: string, category: string) => {
    const isOpen = isOpenCategory.some(cat => cat.category === category && cat.level === level);
    if (isOpen) {
      setOpenCategory(prevState => prevState.filter(cat => !(cat.category === category && cat.level === level)));
    } else {
      setOpenCategory(prevState => [...prevState, { level, category }]);
    }
  };

  useEffect(() => {
    if (!products) {
      setError('Error fetching products list.');
    }

    const initialLevels = products.map(item => item.level);
    const initialCategories = products.flatMap(item =>
      item.categories.map(category => ({ level: item.level, category: category.category }))
    );

    setOpenLevelOfActivation(initialLevels);
    setOpenCategory(initialCategories);
  }, [products]);

  return (
    <div>
      { products
        ? (
            products.map(item => {
              const totalProducts = item.categories.reduce((acc, category) => acc + category.products.length, 0);

              return (
            <StyledBox key={item.level}>
              <StyleDisplayRow onClick={() => { handleLevelOfActivationClick(item.level); }}>
                <StyledIconArrow rotated={isOpenLevelOfActivation.includes(item.level)}>
                  <Icon icon={IconName.NAV_ARROW_RIGHT} type={ComponentType.Classic}/>
                </StyledIconArrow>
                <StyledText type={ComponentType.SubTitle}>{item.level}</StyledText>
                <StyledText>{ totalProducts + (totalProducts > 1 ? ProductText.Products : ProductText.Product)}</StyledText>
              </StyleDisplayRow>
              <StyleHorizontallySeparator />
              {item.categories.map(category => (
                isOpenLevelOfActivation.includes(item.level) && (
                  <StyleCategorySection key={category.category}>
                    <StyleDisplayRow onClick={() => { handleCategoryClick(item.level, category.category); }}>
                      <StyledIconArrow rotated={isOpenCategory.some(cat => cat.category === category.category && cat.level === item.level)}>
                        <Icon icon={IconName.NAV_ARROW_RIGHT} type={ComponentType.Classic}/>
                      </StyledIconArrow>
                      <StyledText type={ComponentType.SubTitle}>{toPascalCaseWithSpaces(category.category)}</StyledText>
                    </StyleDisplayRow>
                    {isOpenCategory.some(cat => cat.category === category.category && cat.level === item.level) && (
                      <StyledProductsColumnList>
                        {category.products.map(element => (
                          <ProductsElementList key={element.name} product={element}/>
                        ))}
                      </StyledProductsColumnList>
                    )}
                  </StyleCategorySection>
                )
              ))}
            </StyledBox>
              );
            }))
        : <div>{ error }</div> };
    </div>
  );
}

export default ProductsList;
