import styled from 'styled-components';

export const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const HeaderColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;
    align-items: flex-end;
    text-align: right;

`;

export const HeaderImageColumn = styled.div`
    display: flex;
    align-items: center;
`;

export const HeaderDetailsColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px;
    height: 16vh;
`;

export const HeaderDetailsLine = styled.div`
    display: flex;
    flex-direction: row;
`;

export const HeaderImage = styled.img`
    border: 2px solid black;
    border-radius: 10px;
    width: 150px;
    height: 150px;
    object-fit: cover;
`;

export const HeaderItem = styled.div`
    margin-bottom: 5px;
    margin-right: 20px;
`;

export const HeaderItemRightAlign = styled(HeaderItem)`
    margin-right: 0;
`;

export const HeaderItemFirstElt = styled(HeaderItemRightAlign)`
    margin-top: 20px;
`;

export const HeaderItemLabel = styled.label`
    color: var(--input-background-color);
    font-size: 14px;
`;

export const HeaderBorderedItem = styled.div`
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 5px;
`;

export const EditableField = styled.input`
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 14px;
    &:focus {
        outline: none;
        border-bottom: 1px solid black;
    }
`;

export const EditableTitle = styled(EditableField)`
    font-weight: bold;
    font-size: 26px;
`;

export const EditableFieldAlignRight = styled(EditableField)`
    align-items: flex-end;
    text-align: right;
`;

export const EditableFieldAlignRightBold = styled(EditableFieldAlignRight)`
    font-weight: bold;
    font-size: 19px;
`;

export const EditableFieldBold = styled(EditableField)`
    font-weight: bold;
    font-size: 19px;
`;
