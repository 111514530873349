import styled, { css } from 'styled-components';
import { ComponentType, Format } from '@utils/enum.utils';

interface IStyledButton {
  format?: Format;
  type?: ComponentType
}

export const StyledButton = styled.button<IStyledButton>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--button-background-color);
    border: var(--button-background-color);
    border-radius: var(--border-radius);
    transition: all 0.3s ease;

    ${({ format }) => {
        switch (format) {
            case Format.Icon:
                return css`
                    width: 2rem;
                    height: 2rem;
                    padding: 0.5rem 0.5rem;
                `;
            case Format.Thin:
                return css`
                    width: fit-content;
                    height: var(--height);
                    padding: 0.2rem 0.5rem;
                `;
            case Format.Classic:
                return css`
                    border: 0;
                    background: transparent;
                `;
            case Format.Large:
                return css`
                    height: 100%;
                    padding: 0.5rem ;
                    margin-right: 0.2rem;
                    border-radius: var(--home-season-btn-border-radius);
                `;
            default:
                return css``;
        }
    }}

    ${({ type }) => {
        switch (type) {
            case ComponentType.Button:
                return css`
                    color: var(--text-color-button);
                    &:hover {
                        opacity: 0.6;
                        cursor: pointer; 
                    }
                `;
            case ComponentType.InfoButtonText:
                return css`
                    color: var(--text-color-button);
                    font-size: 14px;
                `;
            case ComponentType.Comment:
                return css`
                    color: var(--light-grey-color)
                `;
            case ComponentType.Waiting:
                return css`
                    color: var(--waiting-color)
                `;
            case ComponentType.Canceled:
                return css`
                    color: var(--error-todo-color)
                `;
            case ComponentType.Error:
                return css`
                    color: var(--error-todo-color)
                `;
            case ComponentType.Validated:
                return css`
                    color: var(--validated-color)
        `;
            case ComponentType.Classic:
                return css`
                    color: var(--text-color)
        `;
            default:
                return css`
                    color: var(--text-color)
                `;
        }
    }}
`;

export const StyledDeleteImageButtonBox = styled.div`
    display: block;
    position: absolute;
    z-index: 2; 
    top: 1rem;
    right: 1rem
`;
