import React, { ReactNode, useState } from 'react';
import { IProduct } from '@models/api/products';
import { Format } from '@utils/enum.utils';
import { StyleHeader } from '@utils/style.utils';
import {
  HeaderItem,
  HeaderItemLabel,
  HeaderRow,
  HeaderImage,
  HeaderDetailsLine,
  HeaderImageColumn,
  HeaderColumn,
  EditableFieldAlignRight,
  EditableField,
  HeaderBorderedItem,
  HeaderItemRightAlign,
  EditableFieldAlignRightBold,
  EditableFieldBold,
  HeaderDetailsColumn, EditableTitle
} from '@components/Headers/Header.styled';
import { Button } from '@src/components';
import { updateNestedField } from '@utils/fonctions.utils';

interface ProductHeaderProps {
  product: IProduct
}

function HeaderItemFirstElt(props: { children: ReactNode }) {
  return null;
}

function ProductPageHeader({ product }: ProductHeaderProps) {
  const [editableValues, setEditableValues] = useState(product);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof IProduct,
    nestedField?: string
  ) => {
    if (nestedField) {
      setEditableValues((prevValues) =>
        updateNestedField(prevValues, field, nestedField as never, e.target.value)
      );
    } else {
      setEditableValues({
        ...editableValues,
        [field]: e.target.value
      });
    }
  };

  return (
    <StyleHeader>
      <HeaderRow>
        <HeaderImageColumn>
          <HeaderImage src={''} alt={product.name} />
          <HeaderDetailsColumn>
              <EditableTitle
                type="text"
                value={editableValues.name}
                onChange={(e) => {
                  handleFieldChange(e, 'name');
                }}
              />
            <HeaderDetailsLine>
              <HeaderItem>
                <HeaderItemLabel>Assortment File: </HeaderItemLabel>
                <EditableField
                  type="text"
                />
              </HeaderItem>
              <HeaderItem>
                <HeaderItemLabel>Additional Photos: </HeaderItemLabel>
                <EditableField
                  type="text"
                />
              </HeaderItem>
              <HeaderItem>
                <HeaderItemLabel>Number of SKUs: </HeaderItemLabel>
                <EditableField
                  type="number"
                  value={editableValues.numberOfSkus}
                  onChange={(e) => { handleFieldChange(e, 'numberOfSkus'); }}
                />
              </HeaderItem>
              <HeaderItem>
                <HeaderItemLabel>Sell Out Ambitions: </HeaderItemLabel>
                <EditableField
                  type="text"
                  value={editableValues.sellOutAmbition}
                  onChange={(e) => { handleFieldChange(e, 'sellOutAmbition'); }}
                />
              </HeaderItem>
            </HeaderDetailsLine>
            <HeaderItem>
              <HeaderItemLabel>Key elements to highlight in visuals: </HeaderItemLabel>
                <HeaderBorderedItem>
                <EditableField
                  type="text"
                  value={editableValues.elementToHighlight}
                  onChange={(e) => { handleFieldChange(e, 'elementToHighlight'); }}
                />
              </HeaderBorderedItem>
            </HeaderItem>
            </HeaderDetailsColumn>
        </HeaderImageColumn>
        <HeaderColumn>
          <Button action={() => {
          }} format={Format.Icon} text={'...'} />
          <HeaderItemFirstElt>
            <EditableFieldAlignRight
              type="text"
              value={editableValues.season}
              onChange={(e) => {
                handleFieldChange(e, 'season', 'seasonId');
              }}
            />
          </HeaderItemFirstElt>
          <HeaderDetailsLine>
            <HeaderItemRightAlign>
              <EditableFieldBold
                type="text"
                value={editableValues.subcategory}
                onChange={(e) => {
                  handleFieldChange(e, 'subcategory', 'category');
                }}
              />
            </HeaderItemRightAlign>
            <HeaderItemRightAlign>
              <EditableFieldAlignRightBold
                type="text"
                value={editableValues.subcategory}
                onChange={(e) => {
                  handleFieldChange(e, 'subcategory', 'name');
                }}
              />
            </HeaderItemRightAlign>
          </HeaderDetailsLine>
            <HeaderItemRightAlign>
              <EditableFieldAlignRightBold
                type="text"
                value={editableValues.level}
                onChange={(e) => {
                  handleFieldChange(e, 'level');
                }}
              />
            </HeaderItemRightAlign>
            <HeaderItemRightAlign>
              <EditableFieldAlignRightBold
                type="text"
                value={editableValues.status}
                onChange={(e) => {
                  handleFieldChange(e, 'status');
                }}
              />
            </HeaderItemRightAlign>
        </HeaderColumn>
      </HeaderRow>
      <HeaderRow>
          <HeaderItem>
            <HeaderItemLabel>MOP owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.owners.MOP}
              onChange={(e) => {
                handleFieldChange(e, 'owners', 'MOP');
              }}
            />
          </HeaderItem>
          <HeaderItem>
            <HeaderItemLabel>Digital owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.owners.digit}
              onChange={(e) => {
                handleFieldChange(e, 'owners', 'digit');
              }}
            />
          </HeaderItem>
          <HeaderItem>
            <HeaderItemLabel>Marketing Product owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.owners.marketingProduct}
              onChange={(e) => {
                handleFieldChange(e, 'owners', 'marketing');
              }}
            />
          </HeaderItem>
        <HeaderItem>
          <HeaderItemLabel>Model shot Image owner: </HeaderItemLabel>
          <EditableField
            type="text"
            value={editableValues.owners.modelShotImage}
            onChange={(e) => {
              handleFieldChange(e, 'owners', 'modelShotImage');
            }}
          />
        </HeaderItem>
        <HeaderItem>
          <HeaderItemLabel>Product Image owner: </HeaderItemLabel>
          <EditableField
            type="text"
            value={editableValues.owners.productImage}
            onChange={(e) => {
              handleFieldChange(e, 'owners', 'productImage');
            }}
          />
        </HeaderItem>
      </HeaderRow>
    </StyleHeader>);
};

export default ProductPageHeader;
