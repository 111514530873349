import React from 'react';
import * as Icons from '@mui/icons-material';
import { StyledButton } from '@components/Button/Button.styled';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';
import { Icon } from '@src/components';

interface IButtonProps {
  action: () => void;
  format?: Format;
  text?: string;
  icon?: keyof typeof Icons;
  type?: ComponentType;
  iconWidth?: string;
  iconHeight?: string
}

function Button({ action, text, icon, format, type, iconWidth, iconHeight }: IButtonProps) {
  const handleClick = () => {
    if (action) {
      action();
    }
  };

  return (
    <StyledButton type={type} format={format} onClick={handleClick}>
      {icon ? <Icon icon={icon} iconWidth={iconWidth} iconHeight={iconHeight} type={type ?? ComponentType.Button} /> : <></>}
      {text && <StyledText type={type ?? ComponentType.Button} format={format}>{text}</StyledText>}
    </StyledButton>
  );
}

export default Button;
