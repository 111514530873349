import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { StyleWidthContainer } from '@utils/style.utils';
import { SeasonPage, HomePage } from '@src/pages';
import ProductPage from '@pages/ProductPage';

function App() {
  return (
    <StyleWidthContainer>
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/season/:seasonId" element={<SeasonPage/>}/>
        <Route path="/product/:productId" element={<ProductPage/>}/>
      </Routes>
    </StyleWidthContainer>
  );
}

export default App;
