import styled, { css } from 'styled-components';
import { ComponentType } from '@utils/enum.utils';

interface IStyledIcon {
  color?: string;
  border?: boolean;
  position?: string;
  type?: string
}

export const StyledIcon = styled.div<IStyledIcon>`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: fit-content;

    ${({ border, color }) => border && css`
        border: 1.5px solid white;
        border-radius: 50%;
    `}

    ${({ position }) => position && position === 'overlay' && css`
        position: absolute;
        bottom: 85%;
        left: 98%;
    `}

    ${({ type }) => {
        switch (type) {
            case ComponentType.Button:
                return css`
                    color: var(--text-color-button);
                `;
            case ComponentType.Arrow:
                return css`
                    color: var(--text-color-button);
                `;
            case ComponentType.Comment:
                return css`
                    color: var(--light-grey-color)
                `;
            case ComponentType.Waiting:
                return css`
                    color: var(--waiting-color)
                `;
            case ComponentType.Canceled:
                return css`
                    color: var(--error-todo-color)
                `;
            case ComponentType.Error:
                return css`
                    color: var(--error-todo-color)
                `;
            case ComponentType.Validated:
                return css`
          color: var(--validated-color)
        `;
            case ComponentType.Classic:
                return css`
          color: var(--text-color)
        `;
            default:
                return css`
                    color: var(--text-color)
                `;
        }
    }}
`;

export const StyledIconArrow = styled.div<{ rotated?: boolean }>`
    color: #fff;
    transition: transform 0.3s ease; 
    cursor: pointer; 
    margin-right: 0.4rem;
    transform: rotate(${props => (props.rotated ? '90deg' : '0deg')});
`;
