export enum LanguageKey {
  AddSeason = 'AddSeason',
  AddNewSeason = 'AddNewSeason',
  NewSeason = 'NewSeason',

  Spring = 'Spring',
  Summer = 'Summer',
  Winter = 'Winter',
  Fall = 'Fall',

  Axis = 'Axis',
  Annexe = 'Annexe',
  Season = 'Season',
  Year = 'Year',
  Name = 'Name',
  DeadlineNoDef = 'DeadlineNoDef',
  DeadlineDef = 'DeadlineDef',
  LogOut = 'LogOut',
  CreateSeasonError = 'CreateSeasonError'
}
