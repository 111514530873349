import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BriefAsset, Home, SeasonalBrief } from '@utils/constantes.utils';
import { IconName } from '@utils/data/enums/icons.enum';
import {
  StyledContentListNavigationBar,
  StyledContentNavigationBar,
  StyledIconNavigationBar,
  StyledNavigationBar,
  StyledTitleNavigationBar
} from '@components/NavigationBar/NavigationBar.styled';
import { StyledIconArrow } from '@components/Icon/Icon.styled';
import { Icon } from '@src/components';
import { ComponentType } from '@utils/enum.utils';
import { SeasonsOrderByYears } from '@models/api/seasons';
import { getSeasons } from '@api/business';
import { StyledText } from '@utils/style.utils';
import { LanguageKey } from '@languages/enum';
import capitalizeFirstLetter from '@utils/format.utils';

interface NavItemProps {
  icon?: IconName;
  label: string;
  onClick?: () => void;
  isCategory?: boolean;
  isSubCategory?: boolean;
  show?: boolean;
  rotated?: boolean;
  isLogOut?: boolean;
  depth?: number;
  children?: React.ReactNode
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  label,
  onClick,
  isCategory = false,
  isSubCategory = false,
  show = true,
  rotated = false,
  isLogOut = false,
  depth,
  children
}) => (
  <div>
    <StyledContentNavigationBar depth={depth} show={show} onClick={onClick} isLogOut={isLogOut}>
      {(isCategory || isSubCategory) && (
        <StyledIconArrow rotated={rotated}>
          <Icon
            icon={IconName.NAV_ARROW_RIGHT}
            type={ComponentType.Arrow}
            iconWidth={isCategory ? '0.7em' : '0.5em'}
            iconHeight={isCategory ? '0.7em' : '0.5em'}
          />
        </StyledIconArrow>
      )}
      {!isCategory && !isSubCategory && icon && (
        <StyledIconNavigationBar>
          <Icon icon={icon} type={ComponentType.Arrow} />
        </StyledIconNavigationBar>
      )}
      {label}
    </StyledContentNavigationBar>
    { children }
  </div>
);

const NavigationBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [seasons, setSeasons] = useState<SeasonsOrderByYears[]>([]);
  const [error, setError] = useState<string>('');

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category === selectedCategory ? '' : category);
    setSelectedSubCategory('');
  };

  const handleSubCategoryClick = (subCategory: string) => {
    setSelectedSubCategory(subCategory === selectedSubCategory ? '' : subCategory);
  };

  useEffect(() => {
    const fetchSeasons = async() => {
      try {
        const seasonsByYears = await getSeasons(false);
        setSeasons(seasonsByYears);
      } catch (error) {
        setError(`Error fetching season: ${error}`);
      }
    };
    fetchSeasons();
  }, [seasons]);

  return (
    <StyledNavigationBar>
      <StyledTitleNavigationBar>
        <StyledText type={ComponentType.BriefAsset}>{BriefAsset}</StyledText>
      </StyledTitleNavigationBar>
      <StyledContentListNavigationBar>
        {seasons.length > 0
          ? (
          <>
            <NavItem icon={IconName.HOME} label={Home} onClick={() => { navigate('/'); }} />
            <NavItem icon={IconName.SUN} label={SeasonalBrief} />
            {seasons.map(item => (
              <React.Fragment key={item.year}>
                <NavItem
                  label={item.year}
                  onClick={() => { handleCategoryClick(item.year); }}
                  isCategory
                  rotated={selectedCategory === item.year}
                >
                  {item.seasons.map(seasonGroup => (
                    <NavItem
                      key={seasonGroup.season}
                      label={capitalizeFirstLetter(seasonGroup.season)}
                      onClick={() => { handleSubCategoryClick(seasonGroup.season); }}
                      isSubCategory
                      show={selectedCategory === item.year}
                      rotated={selectedSubCategory === seasonGroup.season}
                      depth={1}
                    >
                      {seasonGroup.elements.map(element => (
                        <NavItem
                          depth={2}
                          key={element.id}
                          label={element.name}
                          show={selectedCategory === item.year && selectedSubCategory === seasonGroup.season}
                        />
                      ))}
                    </NavItem>
                  ))}
                </NavItem>
              </React.Fragment>
            ))}
          </>
            )
          : (
          <StyledContentNavigationBar show>{error}</StyledContentNavigationBar>
            )}
        <NavItem icon={IconName.LOGOUT} label={t(LanguageKey.LogOut)} isLogOut onClick={() => { /* Log out functionality */ }} />
      </StyledContentListNavigationBar>
    </StyledNavigationBar>
  );
};

export default NavigationBar;
