import styled from 'styled-components';

export const StyledElementsSeasonList = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        margin-right: 2rem;
        width: 14rem;
        position: relative;
    }
`;
